import { defineMessages } from 'react-intl.macro';

const headerMessages = defineMessages({
    goToDetail: {
        id: 'header.goToDetail',
        defaultMessage: 'Class Detail',
    },
    accountDropdown: {
        id: 'header.accountDropdown',
        defaultMessage: 'My account',
    },
    signout: {
        id: 'header.signout',
        defaultMessage: 'Sign out',
    },
    awsTAndC: {
        id: 'header.awsTAndC',
        description: 'Name of aws organization',
        defaultMessage: 'AWS Training and Certification',
    },
    detailPage: {
        id: 'header.detailPage',
        description: 'Detail page for Classroom',
        defaultMessage: 'Classroom Detail Page',
    },
    homepage: {
        id: 'header.homepage',
        description: 'Classroom homepage',
        defaultMessage: 'Homepage',
    },
});

export default headerMessages;
