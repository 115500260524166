import React from 'react';
import { injectIntl } from 'react-intl';

import messages from './Footer.messages';
import './Footer.css';

const insertYear = s => `${new Date().getFullYear()} ${s}`;

const Footer = ({ intl: { formatMessage } }) => {
    const footerText = {
        terms: {
            text: formatMessage(messages.termsLinkText),
            href: formatMessage(messages.termsLink),
        },
        privacy: {
            text: formatMessage(messages.privacyLinkText),
            href: 'https://aws.amazon.com/privacy/?src=aws_training',
        },
        support: {
            text: formatMessage(messages.supportLinkText),
            href: 'https://www.aws.training/Support',
        },
        feedback: {
            text: formatMessage(messages.feedbackLinkText),
            href: 'https://w.amazon.com/bin/view/Beaker/Product_Feedback/',
        },
        copyright: formatMessage(messages.copyrightText),
    };

    return (
        <footer className="footer awsui-util-p-m">
            <div className="footer__body">
                <ul className="footer__body__links awsui-list-unstyled awsui-util-t-c">
                    {['terms', 'privacy', 'support', 'feedback'].map(link => (
                        <li key={link}>
                            <a
                                href={footerText[link].href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {footerText[link].text}
                            </a>
                        </li>
                    ))}
                </ul>
                <p className="footer__body__copyright">&copy; {insertYear(footerText.copyright)}</p>
            </div>
        </footer>
    );
};

export default injectIntl(Footer);
