import React from 'react';

export const usePagination = () => {
    const [pages, pagesSet] = React.useState([undefined]); // first page doesnt require a pagination token
    const [currentPageIndex, currentPageIndexSet] = React.useState(0);
    const [latestViewedPage, latestViewedPageSet] = React.useState(currentPageIndex);

    const addPage = newPage => {
        // check for if we're on the first page and already have some tokens, this means that
        // we already have the first pagination token and want to avoid storing a new version
        // of the first pagination token
        if (currentPageIndex === 0 && pages.length > 1) {
            return;
        }
        return !pages.includes(newPage) ? pagesSet([...pages, newPage]) : undefined;
    };

    const goToPage = target => currentPageIndexSet(target);

    React.useEffect(() => {
        latestViewedPageSet(Math.max(currentPageIndex, latestViewedPage));
    }, [currentPageIndex, latestViewedPage]);

    return {
        addPage,
        goToPage,
        currentPageIndex,
        currentPage: pages[currentPageIndex],
        pagesCount: pages.length,
        hasAnotherPage: pages[latestViewedPage + 1] !== undefined,
    };
};
