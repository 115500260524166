import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'PartnersPage.pageTitle',
        description: 'Partner selection page title',
        defaultMessage: 'Partners',
    },
    partnerSelectionTitle: {
        id: 'PartnersPage.title',
        description: 'Partner selection title',
        defaultMessage: 'Select AWS training partner',
    },
    loadingPartners: {
        id: 'PartnersPage.loading',
        description: 'Text to signal partners are loading',
        defaultMessage: 'Loading partners',
    },
    noPartners: {
        id: 'PartnersPage.noPartners',
        description: 'Text to signal no partners are associated with the user',
        defaultMessage: 'No associated partners',
    },
    loadingFailure: {
        id: 'PartnersPage.loadingFailure',
        description: 'When loading the partners fails',
        defaultMessage: 'There was a problem loading your partners, please reload the page.',
    },
});

export default messages;
