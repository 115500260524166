import React from 'react';
import Select from '@amzn/awsui-components-react-v3/polaris/select';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';

import { messages, timezones } from './TimeZoneSelect.messages';
const timezoneKeys = Object.keys(timezones);

const TimeZoneSelect = ({ selected, onChange, invalid, disabled = false }) => {
    const { formatMessage } = useIntl();

    const timeZoneOptions = timezoneKeys
        .map(key => {
            const label = timezones[key];
            const offset = moment.tz(key)._offset / 60;

            return {
                name: key,
                label: formatMessage(label),
                offset: offset,
            };
        })
        .sort((a, b) => {
            return a.offset - b.offset;
        })
        .map(({ offset, name, label }) => {
            const min = offset * 60;
            const hour = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : min % 60);
            return {
                id: name,
                label: `${formatMessage(messages.timeZoneLabel, {
                    direction: hour.includes('-') ? '' : '+',
                    hour: hour,
                })} ${label}`,
            };
        });

    const getOptionForTimezone = timezone => timeZoneOptions.find(o => o.id === timezone);

    return (
        <Select
            ariaRequired
            filteringType="auto"
            invalid={Boolean(invalid)}
            options={timeZoneOptions}
            selectedOption={getOptionForTimezone(selected)}
            disabled={disabled}
            onChange={onChange}
            placeholder={formatMessage(messages.timeZonePlaceholder)}
            selectedLabel={formatMessage(messages.selectedLabel)}
        />
    );
};

export default TimeZoneSelect;
