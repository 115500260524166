import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { paths } from 'utils/paths';

export const ProviderCtx = React.createContext();

export const PROVIDER_KEY = 'aws.beaker.provider';

export const ProviderCtxProvider = ({ globals, children }) => {
    const [provider, setProvider] = React.useState(
        JSON.parse(globals.localStorage.getItem(PROVIDER_KEY))
    );
    React.useEffect(() => {
        if (!provider) return;
        globals.localStorage.setItem(PROVIDER_KEY, JSON.stringify(provider));
    }, [provider, globals.localStorage]);

    return (
        <ProviderCtx.Provider
            value={{
                provider,
                setProvider,
            }}
        >
            {children}
        </ProviderCtx.Provider>
    );
};

export const useProvider = () => {
    const { provider } = React.useContext(ProviderCtx);
    const history = useHistory();
    const { pathname } = useLocation();

    React.useEffect(() => {
        if (!provider) {
            history.push({ pathname: paths.providersPage, state: { referrer: pathname } });
        }
    }, [provider, history, pathname])

    return provider;
};

export const clearProviderOutsideCtx = globals => globals.localStorage.removeItem(PROVIDER_KEY);
