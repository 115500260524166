import { Auth, Hub } from 'aws-amplify';
import moment from 'moment';
import { getCurrentUser } from '../utils/helpers';

const SESSION_EXPIRED = 'session_expired';
const SESSION_TIMER_INTERVAL = 30000;
/**
 * Start session timer dispatching event(s) to the Hub when the
 * token has expired.
 */
async function startSessionTimer() {
    try {
        const { exp } = await getCurrentUser();
        const intervalId = setInterval(() => {
            const currentTimestamp = moment().unix();
            if (exp <= currentTimestamp) {
                Hub.dispatch(SESSION_EXPIRED);
                clearInterval(intervalId);
            }
        }, SESSION_TIMER_INTERVAL);
    } catch (e) {
        Hub.dispatch(SESSION_EXPIRED);
    }
}

/**
 * Subscribes to the Hub auth messages https://docs.amplify.aws/lib/utilities/hub/q/platform/js#listening-for-messages
 * and starts the session timer. Invokes onSession expired to handle messaging
 */
async function initializeSession({ onSessionExpired }) {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    Hub.listen(SESSION_EXPIRED, onSessionExpired);
    setTimeout(startSessionTimer);
}

async function getAccessToken() {
    let accessToken = '';

    try {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        accessToken = accessToken = user.signInUserSession.idToken.jwtToken;
    } catch (ex) {
        // Needed as Amplify doesn't catch the exceptiion
    }

    return accessToken;
}

export {
    SESSION_EXPIRED,
    SESSION_TIMER_INTERVAL,
    getAccessToken,
    initializeSession,
    startSessionTimer,
};
