import React from 'react';
import { useHistory } from 'react-router-dom';
import { BreadcrumbGroup } from '@amzn/awsui-components-react';

const Breadcrumbs = ({ items }) => {
    const history = useHistory();

    const onBreadcrumbClick = e => {
        e.preventDefault();
        history.push(e.detail.href);
    };

    return <BreadcrumbGroup items={items} onClick={onBreadcrumbClick} />;
};

export default Breadcrumbs;
