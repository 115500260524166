import React from 'react';
import './HeroBanner.scss';

const HeroBanner = ({ title }) => {
    return (
        <div className="banner-header">
            <h1 className="banner-title">{title}</h1>
        </div>
    );
};

export default HeroBanner;
