import React from 'react';
import { useIntl } from 'react-intl';
import { Table, TablePagination, TableSorting } from '@amzn/awsui-components-react';

import { messages } from './ClassTable.messages';

const ClassTable = ({
    columnDefinitions,
    data,
    isLoading,
    onPageChange,
    hasAnotherPage,
    currentPageIndex,
    pagesCount,
}) => {
    const { formatMessage } = useIntl();

    const paginationLabels = {
        previousPageLabel: formatMessage(messages.previousPageLabel),
        nextPageLabel: formatMessage(messages.nextPageLabel),
        pageLabel: pageNumber => formatMessage(messages.pageLabel, { pageNumber }),
    };

    return (
        <div className="awsui-util-container awsui-util-no-gutters">
            <Table
                variant="borderless"
                loading={data.length === 0 && isLoading}
                loadingText={formatMessage(messages.loadingResources)}
                items={data}
                wrapLines
                features={['pagination', 'sorting']}
                columnDefinitions={columnDefinitions}
                header={<h2>{formatMessage(messages.classListHeading)}</h2>}
                empty={
                    <div className="awsui-util-t-c">
                        <div className="awsui-util-pt-s awsui-util-mb-xs">
                            <b>{formatMessage(messages.noClasses)}</b>
                        </div>
                        <p className="awsui-util-mb-s">
                            {formatMessage(messages.noClassesToDisplay)}
                        </p>
                    </div>
                }
            >
                <TableSorting
                    sortingColumn="endsOn-date"
                    disabled
                    sortableColumns={[{ id: 'endsOn-date' }]}
                    sortingAscending
                />
                <TablePagination
                    openEnd={hasAnotherPage}
                    pagesCount={pagesCount}
                    currentPageIndex={currentPageIndex + 1} // adding 1 because polaris starts their page index at 1 where we start ours at 0
                    onPaginationChange={({ detail }) => onPageChange(detail.currentPageIndex - 1)} // subtracting 1 because polaris indexes pages at 1 and we do 0
                    labels={paginationLabels}
                />
            </Table>
        </div>
    );
};

export default ClassTable;
