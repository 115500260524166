import React from 'react';
import { Modal, Button } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import messages from './ErrorFallback.messages';
import { useHistory, useLocation } from 'react-router';

const ErrorFallback = ({ error = {}, resetErrorBoundary }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { formatMessage } = useIntl();
    const { path, statusCode = 500 } = error;

    const getClassroomErrorModalProps = {
        403: {
            header: messages.forbiddenHeader,
            content: messages.forbiddenContent,
            button: messages.forbiddenButton,
        },
        404: {
            header: messages.notFoundHeader,
            content: messages.notFoundContent,
        },
        500: {
            header: messages.serverErrorHeader,
            content: messages.serverErrorContent,
        },
    };

    const goToCreatePage = () => {
        resetErrorBoundary();
        history.push('/class/new');
    };

    return path === 'getClassroom' ? (
        <Modal
            onDismiss={goToCreatePage}
            visible
            header={formatMessage(getClassroomErrorModalProps[statusCode].header)}
            closeLabel={formatMessage(messages.closeModalButtonLabel)}
            footer={
                <span className="awsui-util-f-r">
                    <Button
                        onClick={() => {
                            goToCreatePage();
                        }}
                        variant="primary"
                    >
                        {formatMessage(messages.getClassroomErrorButton)}
                    </Button>
                </span>
            }
        >
            {formatMessage(getClassroomErrorModalProps[statusCode].content, {
                pageType: pathname.split('/').pop() === 'edit' ? 'edit' : 'view',
            })}
        </Modal>
    ) : (
        <Modal
            visible
            header={formatMessage(messages.serverErrorHeader)}
            closeLabel={formatMessage(messages.closeModalButtonLabel)}
        >
            {formatMessage(messages.serverErrorContent)}
        </Modal>
    );
};

export default ErrorFallback;
