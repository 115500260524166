import React, { useState } from 'react';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useLocation } from 'react-router-dom';
import translations from '../../i18n';
import LocaleContext from 'modules/context/localeContext';
import localStorageDatabase from 'modules/localStorageDb';
import { isLanguageCustomizablePage } from 'utils/paths';

const LangLocale = ({ children }) => {
    const { pathname } = useLocation();
    const [{ locale, userSelected }, localeSet] = useState({
        locale: localStorageDatabase.selectedLocale(),
        userSelected: false,
    });

    const canChangeLanguage = isLanguageCustomizablePage(pathname)

    useEffect(() => {
        if (userSelected) {
            localStorageDatabase.set({ id: 'locale', value: locale });
        }
    });

    return (
        <LocaleContext.Provider
            value={[
                { locale, userSelected, canChangeLanguage },
                localeSet,
            ]}
        >
            <IntlProvider locale={locale} messages={translations[locale]}>
                {children}
            </IntlProvider>
        </LocaleContext.Provider>
    );
};

export default LangLocale;
