import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Alert } from '@amzn/awsui-components-react';
import { RUNTIME_ERROR_NAME, AppError } from '../../utils/error';
import errorMessages from '../../utils/error.messages';

class ComponentDidCatch extends React.Component {
    state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error) {
        new AppError(error.toString(), {
            type: RUNTIME_ERROR_NAME,
        });
    }

    render() {
        const { intl } = this.props;
        if (this.state.hasError) {
            return (
                <Alert
                    visible={this.state.hasError}
                    type="error"
                    header={intl.formatMessage(errorMessages.genericErrorSomethingWentWrong)}
                >
                    <FormattedMessage {...errorMessages.genericErrorTryAgain} />
                </Alert>
            );
        }

        return this.props.children;
    }
}

export default injectIntl(ComponentDidCatch);
