import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    loading: {
        id: 'classPage.loading',
        defaultMessage: 'Loading class data...',
    },
    header: {
        id: 'classPage.header',
        defaultMessage: 'Description',
    },
    sectionHeading: {
        id: 'classPage.section.heading',
        description: 'Section title for the class lab list',
        defaultMessage: 'Labs',
    },
});

export default messages;
