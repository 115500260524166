/**
 * Helper method to create a column aria-label that includes applied sorting preferences.
 * Ex: Partner name, sorted ascending.
 *
 * @param {Object} sortState Current sort state data
 * @param {String} columnHeaderText Current column name
 * @param {String} columnId Current column id
 * @return Column label with applied sorting preference
 */
export const getColumnLabelBase = (notSortedMsg, sortedAscMsg, sortedDescMsg) => (
    sortState,
    columnId,
    columnHeaderText
) => {
    const columnIsSorted = sortState.sortingColumn === columnId;
    const ascending = !sortState.sortingDescending;

    const sortStateMsg = !columnIsSorted ? notSortedMsg : ascending ? sortedAscMsg : sortedDescMsg;
    return `${columnHeaderText}, ${sortStateMsg}.`;
};
